<template>
  <div class="imageBackground">
    <div class="contentContainer">
      <custom-header />
      <div class="mainContainer">
        <div class="btnRow">
          <div class="btn">
            <button class="btnContainer bg-pink" @click="goToCalc()">
              <img :src="historiaImg" class="btnIcon" style="width: 76%" />
              <p class="btnText">{{ $t("labels.myStory") }}</p>
            </button>
          </div>
          <div class="btn">
            <button class="btnContainer bg-yellow" @click="goToDayNote()">
              <img :src="hablandoImg" class="btnIcon" />
              <p class="btnText">{{ $t("labels.whatTalkingAbout") }}</p>
            </button>
          </div>
        </div>
        <div class="btnRow">
          <div class="btn">
            <button
              class="btnContainer bg-lightprimary"
              @click="goToPersonal()"
            >
              <img :src="personalisimoImg" class="btnIcon" />
              <p class="btnText">{{ $t("labels.personal") }}</p>
            </button>
          </div>
          <div class="btn">
            <button class="btnContainer bg-primary" @click="goToForum()">
              <img :src="comunidadImg" class="btnIcon" />
              <p class="btnText">{{ $t("labels.community") }}</p>
            </button>
          </div>
        </div>
        <div class="btnRow">
          <div class="btn">
            <button class="btnContainer bg-green" @click="goToBesukhaForum()">
              <img :src="beImg" class="btnIcon" />
              <p class="btnText">
                {{ $t("labels.meetBeSukha") }}
              </p>
            </button>
          </div>
          <div class="btn">
            <button class="btnContainer bg-blue" @click="goToVideos()">
              <img :src="videosImg" class="btnIcon" />
              <p class="btnText">
                {{ $t("labels.videos") }}
              </p>
            </button>
          </div>
        </div>
        <button class="authBtnContainer bg-orange" @click="goToMyStory()">
          {{ $t("labels.start") }}
        </button>
      </div>
    </div>
  </div>
</template>


<script>
import { mapState } from "vuex";
import CustomHeader from "../components/CustomHeader.vue";
export default {
  name: "DashboardPage",
  components: {
    CustomHeader,
  },
  data() {
    return {
      historiaImg: require("../assets/image/svg/historia_new.svg"),
      videosImg: require("../assets/image/svg/videos.svg"),
      hablandoImg: require("../assets/image/svg/hablando.svg"),
      personalisimoImg: require("../assets/image/svg/personalisimo.svg"),
      comunidadImg: require("../assets/image/svg/comunidad.svg"),
      beImg: require("../assets/image/svg/be.svg"),
      name: "",
      email: "",
      birthday: "",
      gender: "",
      genders: [
        {
          value: "1",
          label: "Chico",
        },
        {
          value: "2",
          label: "Chica",
        },
        {
          value: "3",
          label: "Otro",
        },
      ],
    };
  },

  methods: {
    goBack() {
      window.history.length > 1 ? this.$router.go(-1) : this.$router.push("/");
    },
    goToCalc() {
      this.$router.push("/calc");
    },
    goToMyStory() {
      this.$router.push("/myStory");
    },
    goToDayNote() {
      this.$router.push("/dayNote");
    },
    goToForum() {
      this.$router.push("/forum");
    },
    goToPersonal() {
      this.$router.push("/personal");
    },
    goToVideos() {
      this.$router.push("/videos");
    },
    goToBesukhaForum() {
      if (this.forums.length == 0) return;
      let url = this.forums.find((x) => x.id === 10).url;
      if (!url) return;
      window.open(url, "_blank");
    },
  },
  //   watch: {
  //     name: {
  //       handler(newVal, oldVal) {
  //         console.log(newVal);
  //         console.log(oldVal);
  //       },
  //       immediate: true,
  //     },
  //   },
  computed: mapState({
    forums: (state) => state.main.forums,
  }),
};
</script>

<style scoped>
.btnRow {
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
}
.btn {
  margin: 3px;
  overflow: hidden;
  border-radius: 5px;
  width: calc(30% + 15px);
  padding-top: calc(30% + 20px);
  position: relative;
}
.btnContainer {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding-top: 15px;
  padding-bottom: 5px;
  cursor: pointer;
}
.btnContainer:hover {
  opacity: 0.8;
}
.btnIcon {
  width: 60%;
  height: 60%;
}
.btnText {
  color: white;
  font-size: 13px;
  text-align: center;
  margin: 1em 0px;
}
@media only screen and (max-width: 700px) {
  .btnText {
    font-size: 11px;
    margin: 0px;
  }
}
</style>